
// auto generated by ../update-env.mjs, dont change
export const BUILD="20221205-112101147"
export const CLUSTER="mainnet-beta"
export const STRIPE_KEY="pk_live_51LkjE0JkOPYP00L0bKCBThEdn24kvHEJgZYaDOwhNpojh0Yd6QMVR6PSuKwiJrg2csXtN3rGg9bhDdRbJW5ey8HL00JKtYlsa4"
export const USDC_ADDRESS="EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
export const USDC_DECIMALS=6
export const USDC_SYMBOL="USDC"
export const auctionHouseAddress="6Ct3tuJF6LLU8avQbMitVLXoGPgBwp4H4AV9iSUpexrN"
export const connectionUrl="https://multi-necessary-shard.solana-mainnet.discover.quiknode.pro/72840be85bf07bc471756a8690717b92467e77a9/"


import  {
    Connection,
    PublicKey,
    clusterApiUrl,
    Keypair,
    Transaction,
    sendAndConfirmTransaction
} from "@solana/web3.js";
import {getDecrypt} from "@/aes";
import {CLUSTER, auctionHouseAddress, USDC_DECIMALS, USDC_SYMBOL, connectionUrl} from "@/env";
import { Metaplex, keypairIdentity, token } from "@metaplex-foundation/js";
import {fetchGETauth} from "@/fetch-helpers";


async function getWallet()
{
    const keys = await getDecrypt("keys")
    const wallet = Keypair.fromSecretKey(new Uint8Array(keys.privateKey))

    const connection = new  Connection(connectionUrl);
    const mx = new Metaplex(connection).use(keypairIdentity(wallet))
    return {mx, connection, wallet}
}

async function getAuctionHouse()
{
    const {mx, connection, wallet} = await getWallet()
    const ahKey = new PublicKey(auctionHouseAddress)
    const auctionHouse = await mx
        .auctionHouse()
        .findByAddress({ address:ahKey });

    return {mx, auctionHouse, connection, wallet}
}

export async function list(mintAddress, amount)
{
    const {mx, auctionHouse} = await getAuctionHouse();
    const price = token(amount, USDC_DECIMALS, USDC_SYMBOL);

    const {listing}  = await mx
        .auctionHouse()
        .list({
            auctionHouse,
            mintAccount: new PublicKey(mintAddress),
            price
        });


    return listing.tradeStateAddress.toString();


}

export async function cancelListing(mint)
{
    const {mx, auctionHouse} = await getAuctionHouse();

    const listing = await mx
        .auctionHouse()
        .findListingByTradeState({
            tradeStateAddress: new PublicKey(mint.listingAddress),
            auctionHouse,
        });

    await mx.auctionHouse().cancelListing({ auctionHouse, listing });

    await fetchGETauth(`/bs/list-cancel/${mint.address}`)
    mint.price = 0;
    delete mint.listingAddress;
}

export async function buy(listingAddress)
{
    const {mx, auctionHouse} = await getAuctionHouse();

    const listing = await mx
        .auctionHouse()
        .findListingByTradeState({
            tradeStateAddress: new PublicKey(listingAddress),
            auctionHouse,
        })


    const { purchase } = await mx
        .auctionHouse()
        .buy({
            auctionHouse,
            listing,
            printReceipt: false, // think its cheaper to do this way
        })


    return purchase;
}


export const getViewerSettings  = () => {
    const defaultSettings =    {
        fontSize: 12,
        fontFamily: "Roboto Serif",
        backgroundColor: "white",
    }
    let ret = defaultSettings;
    let vs = localStorage.getItem("viewerSettings")
    if (vs)
    {
        try {
            ret = JSON.parse(vs)
        }catch(e){}
    }
    return ret;
}
export const saveViewerSettings = (vs) => {
    localStorage.setItem("viewerSettings",  JSON.stringify(vs) )
}


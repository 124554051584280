import {getDecrypt} from './aes.js'

import bs58 from 'bs58'
import nacl from "tweetnacl";
import countriesArray from "@/countries.json";
import {CLUSTER} from "@/env";


const sign = (msg, secretKeyBytes)=>{

    const encodedMessage = new TextEncoder().encode(msg);
    let  sig =   nacl.sign.detached(encodedMessage, secretKeyBytes);
    sig = bs58.encode(sig);
    return sig;

}


export const makeAuth = (keys) =>{
    let timestamp  =  Date.now() + "";
    let sig = sign(timestamp, Uint8Array.from(keys.privateKey) )
    let tok =  keys.publicKey.toString() + "-" + sig + "-"  + timestamp

    document.cookie = "auth=" + tok
    return tok
}

export const  blockChainURL = add => `https://explorer.solana.com/address/${add}?cluster=${CLUSTER}`

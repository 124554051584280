<script setup>
import {ref, onBeforeMount, onMounted, watch, computed} from 'vue'
import {ensureKeysExist} from "../aes";
import {useRouter} from 'vue-router'
import {fetchNFTs,balances} from '../nft-list.js'
import {fetchGET, urlPrefix} from "../fetch-helpers.js";
import BookInfoDlg from "../components/book-info-dlg";
import {makeAuth} from "@/utils";

const router = useRouter()
const loading = ref(false)
const bookInfoDialog = ref(null)
const showWaitingDlg = ref(false)
const waitingDlgTitle = ref("")
const waitingDlgSpinner = ref(false)

const nfts = ref([])
import {cancelListing} from "@/auction"

const onNFTupdate = (newList) => {

    nfts.value = newList;
}

onBeforeMount(async () => {

    loading.value = true;
    await ensureKeysExist();
    nfts.value = await fetchNFTs(onNFTupdate);
    loading.value = false;

    // check if we have nagged them to back up yet.  Only do so if there is some value in the wallet.
    if ( (balances.sol || balances.usdc || nfts.value.length) && !localStorage.getItem("backupNag"))
        return router.push("/first-time")

})

const cardWidth = 250;
const cardHeight = 420;
const gap = 20;
const rows = ref([])


const onResize = () => {
    let numCols = Math.floor(window.innerWidth / (cardWidth + gap * 2));
    if (numCols == 0)
        numCols = 1;

    let numRows = Math.ceil(nfts.value.length / numCols);
    rows.value = []

    for (let r = 0; r < numRows; r++) {
        let start = r * numCols;
        let row = nfts.value.slice(start, start + numCols)
        if (!row || !row.length)
            break;
        rows.value.push(row)
    }

}
watch(nfts, onResize);


const coverImage = mint => urlPrefix + '/epub-images/' + mint.coverImage;
const info = mint => bookInfoDialog.value.showDlg(mint);
const read = mint => router.push('/reader/' + mint.bookId);

const listDetails = async mint => {
    let r = await window.$msgBox(mint.bookTitle, "Listed for $" + mint.price.toFixed(2), "Cancel listing|close");
    if (r == "Close")
        return;

    if (r == "Cancel listing") {
        try {
            waitingDlgTitle.value = "Listing cancellation in progress.."
            waitingDlgSpinner.value = true;
            showWaitingDlg.value = true;
            await cancelListing(mint);
            waitingDlgTitle.value = "Cancelled"
            waitingDlgSpinner.value = false;
        } catch (e) {
            showWaitingDlg.value = false;
            window.$msgBox("error", e);
        }
    }
    /* if (r == "Modify price")
     {
         window.$mintToSend = mint;
         router.push("/list")
     }*/
}

</script>


<template>

    <v-container theme="dark" fluid v-resize="onResize">
        <v-progress-circular v-if="loading" class="center" size="150" indeterminate
                             color="purple"></v-progress-circular>


        <div tile class="d-flex justify-center" v-for="row in rows">

            <!--v-card  v-for="mint in row"  :width="cardWidth" :height="cardHeight" class="ma-5"-->
            <div v-for="mint in row" class="ma-5">
                <div :style="`
                            width:${cardWidth}px;
                            height:${cardHeight}px;`">

                    <div :style="`
                            width:${cardWidth}px;
                            height:${cardHeight-45}px;
                            cursor:pointer;`"
                         @click="read(mint)">
                        <div :style="`
                                height:100%;
                                background-image: url('${coverImage(mint)}');
                                background-size: contain;
                                background-position: center bottom;
                                `">
                        </div>
                    </div>
                    <div class="text-center" style="position:relative;top:5px;">
                        <v-btn
                            @click="info(mint)"
                            rounded
                            prepend-icon="mdi-information-outline"
                        >#{{ mint.num }}/{{ mint.numMints }}
                        </v-btn>
                        <v-btn
                            v-if="mint.price"
                            rounded
                            class="ml-5"

                            @click="listDetails(mint)"
                        >
                            ${{ mint.price.toFixed(2) }}
                        </v-btn>

                    </div>
                </div>
            </div>
        </div>


        <v-row v-if="!loading && nfts.length == 0">
            <v-col class="text-center mt-5">
                You have no books. You can buy them from the <a href="/#shop/all">shop</a>.
            </v-col>
        </v-row>
        <book-info-dlg ref="bookInfoDialog"></book-info-dlg>
    </v-container>

    <v-dialog v-model="showWaitingDlg" max-width="800px" persistent>
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>{{ waitingDlgTitle }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <br>
                <div v-if="waitingDlgSpinner" style="text-align: center">
                    <v-progress-circular size="50" indeterminate=""></v-progress-circular>
                </div>
                <div v-else>
                    Complete.
                </div>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="showWaitingDlg = false" :disabled="waitingDlgSpinner">Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

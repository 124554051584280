
<template>
    <v-app theme="dark">
        <v-app-bar theme="dark" app v-if="$route.name != 'reader'">
            <v-app-bar-nav-icon  @click.stop="showNavDraw = !showNavDraw"></v-app-bar-nav-icon>



            <v-toolbar-title>{{$route.name}}</v-toolbar-title>


            <v-spacer></v-spacer>
            <v-img
                class="mx-2"
                src="/bookminter3.svg"
                max-height="50"
                max-width="50"
                contain
            ></v-img>
        </v-app-bar>
        <v-navigation-drawer
            v-if="$route.name != 'reader'"
            theme="dark"
            v-model="showNavDraw"
            bottom
            temporary
        >

            <v-list nav>
                <div v-for="(link, i) in links">

                    <v-list-item
                        v-if="!link.subLinks"
                        :prepend-icon="link.icon"
                        :title="link.text"
                        :to="link.to"
                        @click="menuclick(link)"
                    ></v-list-item>

                    <v-list-group
                        v-else
                        :key="link.text"
                        no-action
                    >
                        <template v-slot:activator="{props}">
                            <v-list-item
                                v-bind="props"
                                :prepend-icon="link.icon"
                                :title="link.text"
                            ></v-list-item>
                        </template>

                        <v-list-item
                            v-for="sublink in link.subLinks"
                            :prepend-icon="sublink.icon"
                            :title="sublink.text"
                            :to="sublink.to">

                        </v-list-item>
                    </v-list-group>

                </div>

            </v-list>
        </v-navigation-drawer>
        <v-main>
            <router-view/>
            <!-- this caused an issue when coming back from reader -->
            <!--router-view v-slot="{ Component, route }">
                <transition name="slide-x" mode="out-in">
                    <component :is="Component" :key="route.path" />
                </transition>
            </router-view-->
        </v-main>

        <v-footer app  theme="dark" v-if="$route.name != 'reader'">
                <img src="/bookminter2.svg" width="120">
            <v-spacer></v-spacer>
                SOL: {{balances.sol}}&nbsp;&nbsp;&nbsp;
                USDC: {{balances.usdc}}


        </v-footer>
    </v-app>
    <message-box ref="msgBox"></message-box>

</template>
<style>
.bookTitle {
    white-space:nowrap;
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    display:inline-block;
    width: 250px;
}
.bookTitle:hover{
    overflow:visible;
    white-space:normal;
}
/* v-slide-x-transition look a like */
.slide-x-enter-active,
.slide-x-leave-active {
    transition: transform .3s cubic-bezier(0.25, 0.8, 0.5, 1), opacity .4s;
    opacity: 1;
}

.slide-x-enter-from,
.slide-x-leave-to {
    opacity: 0;
}

.slide-x-enter-from {
    transform: translateX(100px);
}

.slide-x-leave-to {
    transform: translateX(-100px);
}
</style>


<script>
import wb from "./registerServiceWorker";

import MessageBox from "./components/message-box.vue"
import {del, keys} from "idb-keyval";
import {CLUSTER}  from "@/env.js"
import {balances} from "@/nft-list";

export default {
    name: 'App',
    components: {
        MessageBox
    },
    created() {
        wb.addEventListener("waiting", () => {
            this.showUpdateUI = true;
        });
    },
    data: () => ({
        showNavDraw: false,
        cluster: CLUSTER,
        balances,
        links: [
            {
                to     : '/',
                icon   : 'mdi-home',
                text   : 'Home',
            },
            {
                to     : '/security',
                icon   : 'mdi-shield-key',
                text   : 'Security',
            },
            {
                to     : '/receive',
                icon   : 'mdi-qrcode',
                text   : 'Receive',
            },
            {
                to     : '/shop/all',
                icon   : 'mdi-cart',
                text   : 'Shop',
            },
            {
                to     : '/activity',
                icon   : 'mdi-run',
                text   : 'Activity Log',
            },
          /*  {
                icon     : 'mdi-security',
                text     : 'Security',
                subLinks : [
                    {
                        text : 'Export Private Key',
                        to    : '/wallet/export',
                        icon: 'mdi-shield-key'
                    },
                    {
                        text : 'Restore Wallet',
                        to    : '/restore-wallet-from-pass-phrase',
                        icon: 'mdi-delete'
                    },
                    {
                        text : 'Restore Wallet',
                        to    : '/restore-wallet-from-private-key',
                        icon: 'mdi-delete'
                    },
                    {
                        text : 'Secret Recovery Phrase',
                        to    : '/secret-recovery-phrase',
                        icon: 'mdi-eye'
                    },
                ]
            },*/

            {
                click : "reload",
                icon : "mdi-reload",
                text : "Reload",
            },
            {
                click: "clearCache",
                icon : "mdi-delete",
                text : "Clear Cache",
            },
            {
                to     : '/about',
                icon   : 'mdi-information',
                text   : 'About',

            }
        ]

    }),
    mounted() {
        window.$msgBox = this.$refs.msgBox.show;
    }
    , methods: {
        reload() {
            document.location.reload()
        },
        async accept() {
            this.showUpdateUI = false;
            await wb.messageSW({ type: "SKIP_WAITING" });
        },
        menuclick(men)
        {
            if (men.click)
                this[men.click]();
        },
        async clearCache() {

            const dbKeys = (await keys()).filter( key => key.startsWith("book"));
            for (let dbKey of dbKeys)
                await del(dbKey);
            window.$msgBox("Done", "Book cache cleared, memory has been released.");
            this.showNavDraw = false;
        }

    }

}
</script>

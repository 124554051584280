import {reactive, ref, watch} from "vue";
import {getDecrypt} from './aes.js'
import {fetchGET} from "./fetch-helpers.js"
import {get, set, del, keys} from "idb-keyval"
import {makeAuth} from "@/utils";
import {connectionUrl} from "@/env";
import {TOKEN_PROGRAM_ID} from "@solana/spl-token"
import {
    Connection,
    PublicKey,
    clusterApiUrl,
    Keypair,
    Transaction,
} from "@solana/web3.js";
import getBalance from "@/get-balance";


let eventCallback = null;
export const balances = reactive({usdc: 0, sol: 0})
export const publicKeyStringRef = ref("")

setTimeout( sync, 60000*10) // every 10 mins

let subId = 0;
let subIdAccChange = 0;

watch(publicKeyStringRef, async () => {
    console.log("keywatchx: " + publicKeyStringRef.value)
    if (!publicKeyStringRef.value)
        return;

    const connection = new Connection(connectionUrl);
    if (subId)
        connection.removeProgramAccountChangeListener(subId);

    // this is a special filter that looks for changes involving our public key
    let filters  = [  { dataSize: 165},  { memcmp: {  offset: 32, bytes: publicKeyStringRef.value } } ]
    subId = connection.onProgramAccountChange(TOKEN_PROGRAM_ID, async () => {
       console.log("onProgramAccountChange")

        let bal =  await getBalance(publicKeyStringRef.value);
        balances.sol = bal.sol
        balances.usdc = bal.usdc;

       sync();
    }, "finalized", filters);


    let bal =  await getBalance(publicKeyStringRef.value);
    balances.sol = bal.sol
    balances.usdc = bal.usdc;
    console.log("balances .sol = "+balances.sol)

    if (subIdAccChange)
        connection.removeAccountChangeListener(subIdAccChange);

    subIdAccChange = connection.onAccountChange( new PublicKey(publicKeyStringRef.value), async(updatedAccountInfo, context) =>{
        let bal =  await getBalance(publicKeyStringRef.value);
        balances.sol = bal.sol
        balances.usdc = bal.usdc;
        console.log("balances .sol = "+balances.sol)

    }, "finalized");

    return sync();
});


// we cant do await at top level for some reason
(async () => {
    let keys = await getDecrypt("keys")
    publicKeyStringRef.value = keys ? keys.publicKey : "";
})();

export async function sync() {

    let nfts = []
    // checks with the server we are all good

    try {
        let ourKeys = await getDecrypt("keys")
        if (!ourKeys)
            return nfts;


        try {
            const auth = makeAuth(ourKeys)
            nfts = await fetchGET(`/bs/nft/find?auth=${auth}`)
            nfts = sortNfts(nfts);
        } catch (e) {
            console.log(e)
            return nfts; // try again next time.  we could be offline
        }

        await set("nfts", nfts)

        // check we dont have any books we should not have
        let allKeys = await keys();
        for (const k of allKeys) {
            if (k.startsWith("book")) {
                if (!nfts.find(n => "book" + n.bookId == k)) {
                    console.log("deleting book: " + k)
                    await del(k); // your gone buddy!  Should trigger event so reader doesnt show?
                }

            }
        }
        if (eventCallback)
            eventCallback(nfts);

    } catch (e) {
        console.log(e.toString())
        //window.$msgBox("error fetching nfts", e.toString())
    }
    return nfts;
}

function sortNfts(nfts) {
    // now for nft, checkif we have book and sort according to lastReadTime
    for (const nft of nfts) {
        let time = localStorage.getItem("lastReadTime_" + nft.bookId);
        nft.lastReadTime = time ? parseInt(time) : 0;
    }
    nfts = nfts.sort((a, b) => {
        if (b.lastReadTime != a.lastReadTime)
            return b.lastReadTime - a.lastReadTime

        if (a.bookTitle != b.bookTitle)
            return  a.bookTitle.localeCompare(b.bookTitle, undefined, { sensitivity: 'accent' });

        return a.num - b.num;
    })
    return nfts;
}

export async function fetchNFTs(callback) {
    eventCallback = callback;
    let nfts = await get("nfts")
    if (nfts) {
        nfts = sortNfts(nfts)
        return nfts;
    }
    nfts = await sync()
    return nfts;
}


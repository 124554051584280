import {get, set} from 'idb-keyval';
import {generateMnemonic, mnemonicToSeed} from "web-bip39";
import wordlist from "web-bip39/wordlists/english";
import {Keypair} from "@solana/web3.js";
import {publicKeyStringRef} from "@/nft-list";

async function generateKey() {
    return await crypto.subtle.generateKey({
        "name":"AES-GCM",
        "length":256
    },false,['encrypt','decrypt']);
}
/*
async function exportKey(key) {
    return await crypto.subtle.exportKey('jwk', key);
}

async function importKey(jwk) {
    return await crypto.subtle.importKey('jwk', jwk, {
        "name":"AES-GCM"
    }, false, ['encrypt','decrypt']);
}
*/

export async function encrypt(string,key) {
    let encoded = new TextEncoder().encode(string);
    let iv = crypto.getRandomValues(new Uint8Array(12));
    let encrypted = await crypto.subtle.encrypt({"name":"AES-GCM","iv":iv}, key, encoded);
    return  {"encrypted":encrypted, "iv": iv};
}

export async function decrypt(encrypted,iv, key) {
    let decrypted = await crypto.subtle.decrypt({"name":"AES-GCM","iv":iv}, key, encrypted);
    let decoded = new TextDecoder().decode(decrypted);
    return decoded;
}

export async function getKey()
{
    let key = await get("xxkey");
    if (!key)
    {
        key = await generateKey();
        await set("xxkey", key);
    }
    return key;
}

export async function  setEncrypt(name, ob)
{
    let key = await getKey();
    let enc = await encrypt( JSON.stringify(ob), key);
    await set(name, enc);
}

export async function getDecrypt(name)
{
    let enc = await get(name);
    if (enc == undefined)
        return undefined;
    let key = await getKey();
    let ob = await  decrypt(enc.encrypted, enc.iv, key);
    return JSON.parse(ob);

}


export async function generateKeys()
{

    // Generate a random mnemonic
    const mnemonic = await generateMnemonic(wordlist);


    // generate a keypair from this
    const seed = await mnemonicToSeed(mnemonic, ""); // (mnemonic, password)
    const keypair = Keypair.fromSeed(seed.slice(0, 32));

    let keys = {
        publicKey:keypair.publicKey.toString(),
        privateKey: Array.from(keypair.secretKey),
        phrase: mnemonic,
    }
    publicKeyStringRef.value = keypair.publicKey.toString()
    await setEncrypt("keys", keys)
    return mnemonic;

}

export async function ensureKeysExist()
{
    let keys = await getDecrypt("keys")
    if (keys)
        return;
    await generateKeys();
}

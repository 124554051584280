import {getDecrypt} from "@/aes";
import {makeAuth} from "@/utils";

const getUrlPrefix = ()=>{
    let org = document.location.origin;
    if (org.startsWith("http://localhost") || org.startsWith("http://192.168")) // debugging
        return "http://" + document.location.hostname + ":19019";
    return document.location.origin ;
}

export const urlPrefix = getUrlPrefix()


const handleResponse = async r => {
    let ct = r.headers.get('content-type');
    if (ct.indexOf("application/json") >= 0)
    {
        let resp = await r.json();
        if (r.ok)
            return resp;
        if (resp.message)
            throw new Error(resp.message)
        throw new Error(resp)
    }
    else
    {
        let resp = await r.text();
        if (r.ok)
            return resp;
        if (resp.message)
            throw new Error(resp.message)
        throw new Error(resp)
    }

}

export const fetchPOST = async (url, data) => handleResponse(
    await fetch(urlPrefix + url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {'Content-Type': 'application/json'}
    }))

export const fetchGET = async url => handleResponse(  await fetch(urlPrefix + url));


export const fetchGETauth = async url => {
    let ourKeys = await getDecrypt("keys")
    const auth = makeAuth(ourKeys)
    return fetchGET(url + "?auth="+auth)
}

export const fetchPOSTauth = async (url, data) => {
    let ourKeys = await getDecrypt("keys")
    const auth = makeAuth(ourKeys)
    return fetchPOST(url + "?auth="+auth, data)
}


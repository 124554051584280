import {fetchGET, urlPrefix} from "@/fetch-helpers";
import {get, set} from "idb-keyval";
import {getDecrypt, encrypt, decrypt, getKey} from "@/aes";
import {makeAuth} from "@/utils";


class Ebook {

    constructor() {

    }


    async fetch(bookId, wantHtml) {
        this.bookId = bookId;
        // first see if we have it in indexed storage
        this.book = await get("book" + bookId)
        if (wantHtml && this.book  && this.book.content && this.book.content.encrypted) {
            let key = await getKey()
            this.book.content = await decrypt(this.book.content.encrypted, this.book.content.iv, key)
            this.book.content = JSON.parse(this.book.content);
        }
        if (this.book)
            return this.book;

        this.book = await fetchGET(`/bs/epub/${bookId}`)
        await this.saveBook()
        return this.book;
    }

    async saveBook()
    {
        let key = "book"+this.bookId;
        let content = this.book.content;
        if (content)
        {
            let encKey = await getKey()
            this.book.content = await encrypt( JSON.stringify(content), encKey)
        }
        await set(key, this.book);
        if (content)
            this.book.content = content;
        console.log("saved: "+key )
    }

    async fetchWithChapters(id, chapterCounterPerc) {
        //await this.sleep(3000)
        await this.fetch(id, true);
        if (this.book.content && this.book.content.chapters[0].html  )
        {
            this.calcPercentages()
            return this.book;
        }

        this.auth = makeAuth(  await getDecrypt("keys") )

        // load all chapters
        this.chapterCounterPerc = chapterCounterPerc;
        this.chapterCounter = 0;
        let chapNum = 0;

        await Promise.all(this.book.content.chapters.map(t => this.fetchChapter(chapNum++)))
        this.book.html = "";

        await this.saveBook()
        this.calcPercentages()
    }

    // roughly calculate what percentage of the book size is in each chapter, used by the slider control at the bottom of the reader
    calcPercentages()
    {
        let total = 0;
        let chapters = this.book.content.chapters;
        for (let t of chapters)
            total += t.html.length;
        for(let t of chapters)
            t.perc = 100*t.html.length / total;

    }

    async fetchChapter(num) {
        let t = this.book.content.chapters[num]

        let url =  `/epub/${this.bookId}/__chapter__${num}.html?auth=${this.auth}`; // load preprocessed chapter
        t.html = await fetchGET(url);
        t.html = t.html.replace(/_IMG_PFX_/g, urlPrefix + "/epub/" + this.bookId + "/" )
        this.chapterCounter++
        this.chapterCounterPerc.value  = this.chapterCounter / this.book.content.chapters.length;
    }

    // disable links to web, and make internal links go to right chapter (Still not got #hash part working yet)
    fixupLinks(callback) {


        const links = document.querySelectorAll(".viewer a");
        links.forEach(link => {
            link.addEventListener("click", (ev) => {
                ev.preventDefault()
                ev.stopImmediatePropagation()
                let href = ev.currentTarget.getAttribute("href");
                this.navigateTo(href, callback)
            });
        })


    }
    navigateTo(href, callback)
    {
        if (href.startsWith("http"))
            return; // outside links not currently supported

        let a = href.split("#")
        /*if (a.length > 1)
        {
            // ideally, we take into account filename too.  i.e. its possible chapters to have same ids which will suck
            let el = document.getElementById(a[1]);
            if (el)
                el.scrollIntoView({block:"end"})
            return;
        }*/

        let chapt = this.book.content.chapters.findIndex(t => t.src.endsWith(a[0]))
        if (chapt < 0) {
            // try just the filename part
            let x = a[0].split('/').pop()
            if (!x)
                return;
            chapt = this.book.content.chapters.findIndex(t => t.src.endsWith(x))
        }
        callback(chapt, href)
    }


}

export default Ebook;

import {createRouter, createWebHashHistory} from 'vue-router'
import MyBooksView from '../views/my-books.vue'
import ReaderView from '../views/reader.vue'

const routes = [

    {
        path: '/',
        name: 'My Books',
        component: MyBooksView
    },
    {
        path: '/receive',
        name: 'Receive Address',
        component: () => import(/* webpackChunkName: "first" */ '../views/receive.vue')
    },
    {
        path: '/security',
        name: 'Security',
        component: () => import(/* webpackChunkName: "first" */ '../views/security.vue')
    },
    {
        path: '/secret-recovery-phrase',
        name: 'Secret Recovery phrase',
        component: () => import(/* webpackChunkName: "security" */ '../views/secret-recovery-phrase.vue')
    },
    {
        path: '/shop/:q',
        name: 'Shop',
        component: () => import(/* webpackChunkName: "shop" */ '../views/shop.vue')
    },
    {
        path: '/list',
        name: 'List for sale',
        component: () => import(/* webpackChunkName: "list" */ '../views/list.vue')
    },
    {
        path: '/restore-wallet-from-pass-phrase',
        name: 'Restore wallet from phrase',
        component: () => import(/* webpackChunkName: "security" */'../views/restore-wallet-from-pass-phrase.vue')
    },
    {
        path: '/activity',
        name: 'Activity Log',
        component: () => import(/* webpackChunkName: "security" */'../views/activity.vue')
    },
    {
        path: '/restore-wallet-from-private-key',
        name: 'Restore wallet from key',
        component: () => import(/* webpackChunkName: "security" */ '../views/restore-wallet-from-private-key.vue')
    },
    {
        path: '/send',
        name: 'Send',
        component: () => import(/* webpackChunkName: "send" */ '../views/send.vue')
    },
    {
        path: '/receipt/:orderId',
        name: 'Tax Invoice',
        component: () => import(/* webpackChunkName: "buy" */ '../views/receipt.vue')
    },
    {
        path: '/buy-card',
        name: 'Card Purchase',
        component: () => import(/* webpackChunkName: "buy" */ '../views/buy-stripe.vue')
    },

    {
        path: '/buy-usdc',
        name: 'USDC Purchase',
        component: () => import(/* webpackChunkName: "buy" */ '../views/buy-usdc.vue')
    },
    {
        path: '/first-time',
        name: 'Secret Recovery Phrase',
        component: () => import(/* webpackChunkName: "first" */ '../views/first-time.vue')
    },
    {
        path: '/reader/:id',
        name: 'reader',
        component: ReaderView
    },

    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(),
    routes
})


// taken from https://css-tricks.com/storing-and-using-the-last-known-route-in-vue/
let isFirstTransition = true;
const LS_ROUTE_KEY = "lastRoute"
const LS_LAST_ACTIVITY_AT_KEY = "lastActivityAt"
const MAX_TIME_TO_RETURN = 30 * 24 * 60 * 60 * 1000

router.beforeEach((to, from, next) => {
    const lastRoute = localStorage.getItem(LS_ROUTE_KEY);
    const lastActivityAt = localStorage.getItem(LS_LAST_ACTIVITY_AT_KEY);

    const hasBeenActiveRecently = Boolean(
        lastActivityAt && Date.now() - Number(lastActivityAt) < MAX_TIME_TO_RETURN
    );

    const shouldRedirect = Boolean(
        to.name === "home"
        && lastRoute
        && isFirstTransition
        && hasBeenActiveRecently
    );

    if (shouldRedirect) next(lastRoute);
    else next();

    isFirstTransition = false;
});

router.afterEach(to => {
    localStorage.setItem(LS_ROUTE_KEY, to.path);
    localStorage.setItem(LS_LAST_ACTIVITY_AT_KEY, Date.now());
});

export default router

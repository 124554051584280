<script setup>
import {ref, reactive, onMounted, onBeforeUnmount, computed, nextTick, watch} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {setupTouchEvents, removeTouchEvents, SUPPORTS_TOUCH} from "@/touch-events.js";
import {getViewerSettings, saveViewerSettings} from "@/viewer-settings.js";
import {useDisplay} from 'vuetify'

import TableOfContents from "../components/table-of-contents.vue"

import Ebook from "../ebook.js";

const viewer = ref(null)
const showTOC = ref(false)
const scrollPos = ref(0)
const loading = ref(true)
const htmlContent = ref("");
const overlay = ref(false)
const transitionClass = ref("")
const viewerSettings = reactive(getViewerSettings())
const currentChapterName = ref("")
const chapterCounter = ref(0)
const route = useRoute()
const router = useRouter()
const ebook = new Ebook()
const iconSize = computed(() => useDisplay().mobile ? "" : "large")
const fontList = ['Open Sans', 'Roboto', 'Roboto Serif']
let resizePrevContent = "";
let resizeTimerId = 0;
let maxPos = {
    chap: 0,
    pos: 0,
};
let currentPos = {
    chap: 0,
    pos: 0
}

// TODO: make composables.  e.g.  useTouch

const setLocalStorage = (key, ob) => localStorage.setItem(key, JSON.stringify(ob));
const getLocalStorage = (key) => JSON.parse(localStorage.getItem(key) || "null");

const viewerWidth = () => viewer.value.clientWidth; // fudge for some reasone needed

const savePos = () => {
    if (resizePrevContent)
        return; // dont save while this is going on

    currentChapterName.value = ebook.book.content.chapters[currentPos.chap].name;
    if (viewer.value.scrollWidth == viewer.value.clientWidth)
        currentPos.pos = 0;
    else
        currentPos.pos = viewer.value.scrollLeft / (viewer.value.scrollWidth - viewer.value.clientWidth);

    setLocalStorage("currentPos_" + ebook.bookId, currentPos);

    if (currentPos.pos > maxPos.pos && currentPos.chap >= maxPos.chap) {
        maxPos.chap = currentPos.chap;
        maxPos.pos = currentPos.pos;
        setLocalStorage("maxPos_" + ebook.bookId, maxPos);
    }
}
const loadCurrentChapter = () => {
    let chapter = ebook.book.content.chapters[currentPos.chap];
    htmlContent.value = chapter.html;
    nextTick(() => { // need to wait until chapter is sized and scrollWidth is correct
        let vw = viewerWidth()
        viewer.value.scrollLeft = vw * Math.round(currentPos.pos * (viewer.value.scrollWidth - viewer.value.clientWidth) / vw)
        savePos()
    })


}
const navigate = (chaptNum, href) => {
    currentPos.chap = chaptNum;
    loadCurrentChapter()
    nextTick(() => {
        let a = href.split("#")
        if (a.length > 1) {
            let el = document.getElementById(a[1]);
            if (el)
                el.scrollIntoView({block: "start", inline: "start"})
        }
        savePos()
    })
}

const next = (force) => {
    if ((force || currentPos.pos > 0.999999) && currentPos.chap < ebook.book.content.chapters.length) {
        currentPos.chap++;
        htmlContent.value = ebook.book.content.chapters[currentPos.chap].html;
        viewer.value.scrollLeft = 0;
        savePos();
        return;
    }
    viewer.value.scrollBy({top: 0, left: viewerWidth()})
    savePos();

}
const prev = (force) => {
    if ((force || currentPos.pos < 0.000001) && currentPos.chap > 0) {
        currentPos.chap--;
        currentPos.pos = 1;
        loadCurrentChapter();
        return;
    }

    viewer.value.scrollBy({top: 0, left: -viewerWidth()})
    savePos();
}

const viewerClicked = (ev) => {
    console.log("viewerClicked")
    if (ev.pageX > 3 * viewer.value.clientWidth / 4)
        return next();
    if (ev.pageX < viewer.value.clientWidth / 4)
        return prev();
    overlay.value = true;
    //viewerSettingsDlg.value.showDlg();
    transitionClass.value = "zoom";
}
const hideOverlay = () => {
    overlay.value = false;
    transitionClass.value = "";
}


const onResize = (ev) => {

    // we need to debounce the resize event, as it can take SECONDS! to do on large docs.  So to make snappy resize, we set
    // the html content to just "resizing..." then after a while restore it.
    if (resizeTimerId)
        clearTimeout(resizeTimerId)
    if (!resizePrevContent)
        resizePrevContent = htmlContent.value

    //htmlContent.value = "resizing..."
    resizeTimerId = setTimeout(() => {
        // htmlContent.value = resizePrevContent;
        nextTick(() => {
            resizePrevContent = "";
            loadLastReadPosition()

        })
    }, 500)

}


const loadLastReadPosition = async () => {
    // scroll to last read pos
    currentPos = getLocalStorage("currentPos_" + ebook.bookId);
    if (!currentPos)
        currentPos = {pos: 0, chap: 0};
    maxPos = getLocalStorage("maxPos_" + ebook.bookId);
    if (!maxPos)
        maxPos = {pos: 0, chap: 0};

    /*if (maxPos > currentPos)
    {
        // prompt user to see if they want to navigate to last read pos
    }

     */
    loadCurrentChapter();

}

import colors from "../background-to-text-colors.js"

// as the html in the viewer is not reactive, we need to have a dynamic style sheet to set font size etc.
const updateStyleSheet = () => {

    let sheetToBeRemoved = document.getElementById('viewerStyle');
    if (sheetToBeRemoved) {
        let sheetParent = sheetToBeRemoved.parentNode;
        sheetParent.removeChild(sheetToBeRemoved);
    }

    let sheet = document.createElement('style')
    sheet.innerHTML = `
    .viewer{
        font-size: ${viewerSettings.fontSize}pt;
        font-family: ${viewerSettings.fontFamily};
        background-color: ${viewerSettings.backgroundColor};
        color: ${colors[viewerSettings.backgroundColor].text};
        height: ${(window.innerHeight - 80)}px; /* 80 = 2x margin */
    }
    .v-main {
        background-color: ${viewerSettings.backgroundColor};
     }
    `;
    sheet.id = "viewerStyle"
    document.body.insertBefore(sheet, document.body.firstChild);
}

const navigateToc = (t) => {
    showTOC.value = false;
    ebook.navigateTo(t.src, navigate);

}
const furtherestPage = () => {

    setLocalStorage("currentPos_" + ebook.bookId, maxPos);
    loadLastReadPosition();
}

const onKeydown = (ev) => {
    if (ev.code == "ArrowLeft")
        return prev();
    if (ev.code == "ArrowRight" || ev.code == "Space")
        return next();
}

onMounted(async () => {

    loading.value = true;
    updateStyleSheet();
    try {
        await ebook.fetchWithChapters(route.params.id, chapterCounter)
    } catch (ex) {
        window.$msgBox("error", ex.toString())
        router.replace("/")
        return;
    }

    // loadLastReadPosition();
    loading.value = false;

    localStorage.setItem("lastReadTime_" + ebook.bookId, Date.now() + "");
    window.addEventListener("keydown", onKeydown);
    nextTick(() => { // viewer.value is null unless we wait for a bit
        setupTouchEvents(viewer.value, savePos, next, prev, viewerClicked)
    })

})
onBeforeUnmount(() => {
    let sheetToBeRemoved = document.getElementById('viewerStyle');
    if (sheetToBeRemoved) {
        let sheetParent = sheetToBeRemoved.parentNode;
        sheetParent.removeChild(sheetToBeRemoved);
    }
    window.removeEventListener("keydown", onKeydown);
    removeTouchEvents();

});

let skipWatch = false;
// when user navigates, not using the slider, this is triggered so we need to update scroll bar position
const onScroll = () => {
    if (!htmlContent.value)
        return;

    let chaps = ebook.book.content.chapters;
    let perc = 0;
    let chapNum = 0;
    for (chapNum = 0; chapNum < currentPos.chap; chapNum++)
        perc += chaps[chapNum].perc;

    let gap = chaps[currentPos.chap].perc * viewer.value.scrollLeft / viewer.value.scrollWidth;
    perc += gap;
    scrollPos.value = perc;
    skipWatch = true;

}


watch(htmlContent, () => {
    nextTick(() => ebook.fixupLinks(navigate)); // need to wait for dom to create stuff
})

watch(scrollPos, () => {
    if (!htmlContent.value)
        return;
    if (skipWatch) {
        skipWatch = false;
        return
    }
    // scrollPos between 0 - 100
    // map to actual chapter and pos
    let chaps = ebook.book.content.chapters;
    let perc = 0;
    let chapNum = 0;
    for (const chap of chaps) {
        if (perc + chap.perc >= scrollPos.value)
            break;
        perc += chap.perc;
        chapNum++;
    }
    let gapPerc = (scrollPos.value - perc) / chaps[chapNum].perc;
    // this gap is mapped to current scrollWidth
    currentPos.chap = chapNum;
    currentPos.pos = gapPerc * viewer.value.scrollWidth;
    let vw = viewerWidth()

    loadCurrentChapter()


})

watch(viewerSettings, () => {
    saveViewerSettings(viewerSettings)
    updateStyleSheet();
})

// put delays in as the ui chugs up if we dont
const fontSizeIncreaseLoading = ref(false)
const increaseFontSize = () => {
    fontSizeIncreaseLoading.value = true
    setTimeout(() => viewerSettings.fontSize++, 50)
    setTimeout(() => fontSizeIncreaseLoading.value = false, 300)
}
const fontSizeDecreaseLoading = ref(false)
const decreaseFontSize = () => {
    fontSizeDecreaseLoading.value = true
    setTimeout(() => viewerSettings.fontSize--, 50)
    setTimeout(() => fontSizeDecreaseLoading.value = false, 300)
}

</script>

<style scoped>
html {
    overflow-y: hidden !important;

}


.viewer {
    column-count: 1;
    column-width: 100vw;
    column-fill: auto; /* need this otherwise if first chapter is just a few chars, height is all wrong */
    column-gap: 0px;
    overflow: hidden;
    margin: 40px;
    touch-action: none;
    user-select: none;

}

.footer {
    position: fixed;
    bottom: 0;
    left: 30px;
    width: calc(100vw - 40px);
}

.sliderClass {
    width: calc(100vh - 40px)
}


.columnBreak {
    break-before: column;

}

.zoom {
    /*    transition: all .2s ease-in-out;*/
    transform: scale(0.85);
}


</style>
<template>
    <div v-if="loading" style="margin-left:auto;margin-right:auto;margin-top:30vh;width:150px;height: 100vh;">

        <v-progress-circular v-if="loading" class="center" size="150" indeterminate
                             color="purple"></v-progress-circular>

    </div>
    <div
        v-if="!loading"
        ref="viewer"
        :class="['viewer', transitionClass]"
        v-resize="onResize"
        @click="(ev)=> SUPPORTS_TOUCH ? '' : viewerClicked(ev)"
        v-scroll.self="onScroll"
        :style="`font-size: ${viewerSettings.fontSize};`"
        v-html="htmlContent"
    >
    </div>
    <v-overlay
        v-if="!loading"
        v-model="overlay"
        width="100vw"
        height="100vh"
        @click="hideOverlay()"

    >
        <v-card theme="dark" density="compact">
            <v-toolbar>
                <v-btn to="/" icon class="mr-5">
                    <v-icon>mdi-home</v-icon>
                </v-btn>

                <v-btn
                    icon
                    @click.stop="showTOC = true;hideOverlay();"
                >
                    <v-icon>mdi-table-of-contents</v-icon>
                </v-btn>
                <v-btn
                    icon
                    :loading="fontSizeIncreaseLoading"
                    :disabled="fontSizeIncreaseLoading"
                    @click.stop="increaseFontSize()"
                >
                    <v-icon>mdi-format-font-size-increase</v-icon>
                </v-btn>
                <v-btn
                    icon
                    :loading="fontSizeDecreaseLoading"
                    :disabled="fontSizeDecreaseLoading"
                    @click.stop="decreaseFontSize()"
                >
                    <v-icon>mdi-format-font-size-decrease</v-icon>
                </v-btn>

                <v-menu
                    anchor="bottom end"
                    theme="dark"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn
                            @click.stop=""
                            v-bind="props"
                            icon
                        >
                            <v-icon>mdi-format-font</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="font in fontList"
                            :key="font"
                            @click="viewerSettings.fontFamily=font"
                        >
                            <v-list-item-title>
                                {{ font }}
                                <v-icon v-show="viewerSettings.fontFamily == font"> mdi-check</v-icon>
                            </v-list-item-title>

                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-menu
                    anchor="bottom end"
                    theme="dark"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn
                            icon
                            :color="viewerSettings.backgroundColor"
                            v-bind="props"
                        >
                            <v-icon sizse="large" :color="viewerSettings.backgroundColor">mdi-palette</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="col in Object.keys(colors)"
                            :key="col"
                            @click="viewerSettings.backgroundColor = col"
                        >
                            <v-icon :color="col">mdi-circle</v-icon>
                        </v-list-item>
                    </v-list>

                </v-menu>

                <v-spacer></v-spacer>
                <v-img
                    class="mx-2"
                    src="/bookminter3.svg"
                    max-height="50"
                    max-width="50"
                    contain
                ></v-img>
            </v-toolbar>
        </v-card>

        <div class="footer">

            <p style="position:relative; top:20px; text-align: center;">{{ currentChapterName }}</p>
            <v-slider
                theme="dark"
                class="ml-5 mr-5 mt-3 "
                v-model="scrollPos"
                :max="100"
                :step="0.1"
                :color="colors[viewerSettings.backgroundColor].control"
            ></v-slider>
        </div>

    </v-overlay>
    <TableOfContents v-if="ebook && ebook.book && ebook.book.content.toc &&showTOC" v-model="showTOC"
                     :toc="ebook.book.content.toc" @nav="navigateToc" @furtherest="furtherestPage"></TableOfContents>
</template>

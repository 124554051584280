
<script setup>
import { reactive, defineExpose} from 'vue'


const parms = reactive({} )

let currentPromiseResolve;

const  close = (but) =>{
    //emit('close', but)
    parms.show = false;
    currentPromiseResolve(but)
}

const show =  (title, message, buttons)  =>{
    parms.show = true;
    parms.title = title;
    parms.message = message;

    if (!buttons)
        buttons = "ok";
    parms.buttons = buttons.split("|")
    return new  Promise((resolve) => currentPromiseResolve = resolve )

}


defineExpose({
    show
})
</script>


<template>
    <v-dialog theme="dark" v-if="parms" v-model="parms.show" max-width="800px" persistent>
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>{{parms.title}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <br>
                <div class="body-1" v-html="parms.message"></div>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-for="buttonText in parms.buttons" color="primary" @click.stop="close(buttonText)">{{buttonText}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<template>
    <v-navigation-drawer
        v-model="show"
        absolute
        location="right"
        temporary
        theme="dark"
    >
        <v-list
            nav
            dense
        >
            <v-list-item @click="$emit('furtherest')">
                <v-list-item-title>Furtherest page read</v-list-item-title>
            </v-list-item>

            <v-list-item
                v-for="(t, i) in toc"
                :key="i"
                @click="$emit('nav', t)"
            >
                <v-list-item-title>{{t.name}}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script setup>
import {ref, computed} from 'vue'

const props = defineProps({
    modelValue: Boolean,
    toc: Array,
})
const emit = defineEmits(['nav', 'furtherest', 'update:modelValue'])

const show = computed({
    get: () => props.modelValue,
    set: (value) => {
         emit('update:modelValue', value);
    }
})


</script>

<script setup>

import {defineExpose, ref, reactive, onMounted} from 'vue'
import {urlPrefix} from "@/fetch-helpers";
import {CLUSTER} from "@/env.js"
import {useDisplay} from "vuetify";
import {useRouter} from "vue-router";
import Ebook from "../ebook.js";
import {publicKeyStringRef} from "@/nft-list";

import {get, set} from "idb-keyval";

const book = reactive({})
const show = ref(false)
const coverImage = ref("")
const mint = ref(null)

const display = useDisplay();

const height = ref(800);


const showDlg = async (_mint) => {
    window.$mintToSend = _mint;
    if (display.xs.value) {
        height.value = 220;
        fullScreen.value = true;
    } else
        fullScreen.value = false

    if (display.sm.value)
        height.value = 400;
    if (display.md.value)
        height.value = 500;
    if (display.lg.value)
        height.value = 600;
    if (display.xl.value)
        height.value = 800;

    mint.value = _mint;
    show.value = true;
    book.title = "";
    if (mint.value.book)
        Object.assign(book, mint.value.book);
    else {
        let ebook = new Ebook();
        let b = await ebook.fetch(_mint.bookId);
        Object.assign(book, b);
    }
    //coverImage.value = `${urlPrefix}/epub-images/${b.coverImageOrig}`;

}
const router = useRouter()
const send = ()=>{
    show.value = false;
    router.push("/send")
}
const list = ()=>{
    show.value = false;
    router.push("/list")
}

const fullScreen = ref(false)


defineExpose({showDlg})

</script>

<template>

    <v-dialog v-model="show" :fullscreen="fullScreen" max-width="900" >
        <v-progress-circular v-if="!book.title" size="50" indeterminate=""></v-progress-circular>
        <v-card v-if="!fullScreen"
            class="d-flex flex-row "
            flat
            tile

        >
            <div
                class="pa-2"
                outlined
                tile
            >
                <img :src="`${urlPrefix}/epub-images/${book.coverImageOrig}`" :style="`height:${height}px;`">

            </div>
            <v-card
                class="pa-2"
                flat
                tile
                width="400"

            >
                <v-card-title>{{book.title}}</v-card-title>
                <v-btn
                    v-if="mint.num"
                    :href="`https://explorer.solana.com/address/${mint.address}?cluster=${CLUSTER}`"
                    target="_blank"
                    rounded
                    size="small"
                >#{{ mint.num }} / {{ mint.numMints }} mints
                </v-btn><br/>
                {{ mint.collectionSize - mint.numMints }} remain to be minted in drop {{ mint.releaseNum + 1 }}.
                <br/>
                Maximum collection size: {{mint.collectionSize}}
                <br/>
                <div v-if="book.seriesName">
                    Book {{ book.bookNumberInSeries }} in the {{ book.seriesName }} series.
                </div>

                <br/>
                <br/>
                <div :style="`overflow-y: auto;height:${height - 250}px;white-space:pre-line;`">
                    {{ book.description }}

                </div>
                <p></p>
                <v-card-actions style="position: absolute;bottom:0;right:10px;" >

                    <v-btn v-if="mint.owner == publicKeyStringRef && !mint.price"
                        prepend-icon="mdi-currency-usd"
                        @click="list"
                    >
                        List for Sale
                    </v-btn>
                    <v-btn
                        v-if="mint.owner == publicKeyStringRef && !mint.price"
                        @click="send"
                    >
                        Send
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="show=false"
                        color="primary"
                    >OK</v-btn>

                </v-card-actions>

            </v-card>

        </v-card>
        <v-card v-if="fullScreen" >
            <div
                class="pa-2"
            >
                <img :src="`${urlPrefix}/epub-images/${book.coverImageOrig}`" :style="`width:100%;`">
            </div>
            <v-card-title>{{book.title}}</v-card-title>
            <v-card-text>

                <v-btn
                    :href="`https://explorer.solana.com/address/${mint.address}?cluster=${CLUSTER}`"
                    target="_blank"
                    rounded
                    size="small"
                >#{{ mint.num }} / {{ mint.numMints }} mints
                </v-btn><br/>
                {{ mint.collectionSize - mint.numMints }} remain to be minted in drop {{ mint.releaseNum + 1 }}.
                <br/>
                <div v-if="book.seriesName">
                    Book {{ book.bookNumberInSeries }} in the {{ book.seriesName }} series.
                </div>



                <br/>
                <br/>
                <div :style="`overflow-y: auto;height:${height - 250}px;`">
                    {{ book.description }}
                </div>
                <p></p>

            </v-card-text>
            <v-card-actions  >
                <v-btn
                    v-if="mint.owner == publicKeyStringRef"
                    prepend-icon="mdi-currency-usd"
                    @click="list"
                >
                    List for Sale
                </v-btn>
                <v-btn
                    v-if="mint.owner == publicKeyStringRef"
                    @click="send"
                >
                    Send
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    @click="show=false"
                    color="primary"
                >OK</v-btn>

            </v-card-actions>
        </v-card>


    </v-dialog>
</template>

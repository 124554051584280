// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'


// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
    /*{
        defaults: {
            VAppBar: {
                flat: true,
            },
            VBtn: {
                color: 'primary',
                height: 44,
                rounded: 'sm',
            },
            VSheet: {
                color: '#212121',
            },
        },
        theme: {
            defaultTheme: 'dark',
        },
    }*/
)

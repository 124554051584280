import  {
    Connection,
    PublicKey,
    clusterApiUrl,
    LAMPORTS_PER_SOL ,
} from "@solana/web3.js";

import {CLUSTER, connectionUrl, USDC_ADDRESS} from "@/env";
import {getDecrypt} from "@/aes";

export default async function getBalance(pk)
{
    const ownerKey = new PublicKey(pk);
    const connection = new Connection(connectionUrl);
    let sol = await connection.getBalance(ownerKey);
    sol = sol / LAMPORTS_PER_SOL;

    // either usdc_test or the real usdc

    const bal = await connection.getParsedTokenAccountsByOwner(
        ownerKey,
        { mint: new PublicKey(USDC_ADDRESS) }
    );

    const usdc =  bal?.value[0]?.account.data.parsed.info.tokenAmount.uiAmount ?? 0;
    return {sol, usdc};
}


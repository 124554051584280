
let viewer ;
let savePos;
let lastX = 0;
let lastY = 0;
let startTouchX = 0;
let startTouchY = 0;
let touchStartTime;
let next;
let prev;
let viewerClicked;

export const SUPPORTS_TOUCH = ('ontouchstart' in window || window.navigator.maxTouchPoints > 0);
console.log("supports touch: " + SUPPORTS_TOUCH)

const touchStart = (ev) => {
    if (!viewer)
        return;

    const touch = ev.changedTouches[0];
    lastX = touch.clientX;
    lastY = touch.clientY;
    startTouchX = lastX;
    startTouchY = lastY;
    touchStartTime = new Date().getTime()
}

const touchMove = (ev) => {
    if (!viewer)
        return;
    const touch = ev.changedTouches[0];
    let dx = touch.clientX - lastX;
    lastX = touch.clientX;
    lastY = touch.clientY;
    viewer.scrollBy({top: 0, left: -dx})
}
const touchEnd = (ev) => {
    if (!viewer)
        return;
    const touch = ev.changedTouches[0];

    let dx = touch.clientX - startTouchX;
    let dy = touch.clientY - startTouchY;
    let dist = Math.round( Math.sqrt( dx*dx + dy*dy ))
    if (dist < 10)
    {
        // not a significant move, treat as a click
        viewer.scrollBy({top: 0, left: dx}) // reverse any changes made
        viewerClicked({pageX: touch.clientX, pageY: touch.clientY});
        return;
    }
    // snap to page boundary
    let ms = new Date().getTime() - touchStartTime;
    const vw = viewer.clientWidth;
    let pos;
    if (ms < 200) {
        // a quick swipe, go to the next or prev page
        pos = vw * Math.floor(viewer.scrollLeft / vw);
        if (touch.clientX < startTouchX)
            pos += vw;
    } else {
        // its a long pause, go to nearest page
        pos = vw * Math.round(viewer.scrollLeft / vw);
    }

    savePos()


    let beforeScrollLeft = viewer.scrollLeft;
    viewer.scrollTo({top: 0, left: pos, behaviour: "smooth"})
    if (beforeScrollLeft == viewer.scrollLeft) // i.e no change was made, so we either need to load a new chapter
    {
        let dx = startTouchX - touch.clientX;
        if (dx > 0)
            next(true);
        else
            prev(true);
    }
}

export const setupTouchEvents = (_viewer,  _savePos, _next, _prev, _viewerClicked) => {
    if (!SUPPORTS_TOUCH)
        return;
    viewer = _viewer;
    savePos = _savePos;
    next = _next;
    prev = _prev;
    viewerClicked = _viewerClicked;

    window.addEventListener('touchstart',touchStart);
    window.addEventListener('touchend', touchEnd);
    window.addEventListener('touchcancel',touchEnd);
    window.addEventListener('touchmove', touchMove);

}
export const removeTouchEvents = ()=> {
    if (!SUPPORTS_TOUCH)
        return;

    window.removeEventListener('touchstart',touchStart);
    window.removeEventListener('touchend', touchEnd);
    window.removeEventListener('touchcancel',touchEnd);
    window.removeEventListener('touchmove', touchMove);

}
